import React from "react";
import { monthConverter } from "utils/common";

const TableComponent = ({
  reportsData,
  tableRef,
  reportInfo,
  currentLevel,
  paginationData
}) => {
  let levelName = "";

  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-responsive"
      style={{ height: "560px", overflow: "scroll", backgroundColor: "#fff" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingBottom: 0 }}>
            <h3>SISMAL v3, REGMAL 1</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {periodeName === "Bulanan" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Provinsi Pencatat</th>
            <th>Kabupaten Pencatat</th>
            <th>Faskes Pencatat</th>
            <th>Desa</th>
            <th>Dusun</th>
            <th>Alamat Lengkap dan Nomor Telepon</th>
            <th>Kepemilikan</th>
            <th>Jenis Faskes</th>
            <th>WNA</th>
            <th>Nomor Identitas Kependudukan (NIK)</th>
            <th>Nama Pasien</th>
            <th>Usia</th>
            <th>Tahun/Bulan</th>
            <th>Jenis Kelamin</th>
            <th>Status Kependudukan (Khusus Papua)</th>
            <th>Status Kehamilan</th>
            <th>Provinsi Domisili</th>
            <th>Kabupaten / Kota Domisili</th>
            <th>Kecamatan Domisili</th>
            <th>Fasyankes Domisili</th>
            <th>Desa Domisili</th>
            <th>Pasien Beralamat (KTP) Diluar Wilayah</th>
            <th>Provinsi KTP</th>
            <th>Kab/Kota KTP</th>
            <th>Kecamatan KTP</th>
            <th>Faskes KTP</th>
            <th>Asal Penemuan</th>
            <th>Kegiatan Penemuan</th>
            <th>SSR</th>
            <th>Kader</th>
            <th>Tanggal MBS</th>
            <th>Tanggal Survei Kontak</th>
            <th>Kasus Pertama Survei Kontak</th>
            <th>Jenis Pemeriksaan</th>
            <th>Total RDT</th>
            <th>No Registrasi Pasien</th>
            <th>Tanggal Kunjungan Pasien</th>
            <th>Tanggal Pemeriksaan Lab</th>
            <th>Kode Slide</th>
            <th>Jenis Parasit</th>
            <th>Pekerjaan</th>
            <th>Derajat Malaria</th>
            <th>Kekambuhan</th>
            <th>Suhu Tubuh (Celcius)</th>
            <th>Gejala</th>
            <th>Tanggal Gejala</th>
            <th>Gametosit</th>
            <th>Kepadatan parasit</th>
            <th>Berat badan (Kg)</th>
            <th>Anemia</th>
            <th>Asal Penularan</th>
            <th>Klasifikasi Kasus</th>
            <th>Tanggal Pengobatan</th>
            <th>DHP</th>
            <th>Primaquine</th>
            <th>Kina Tablet</th>
            <th>Kina Injeksi</th>
            <th>Artesunat Injeksi</th>
            <th>Doksisiklin</th>
            <th>Tetrasiklin</th>
            <th>Klindamisin</th>
            <th>Status Pengobatan</th>
            <th>Alasan Tidak Standar</th>
            <th>Perawatan</th>
            <th>Kematian dengan Malaria</th>
            <th>Dirujuk</th>
            <th>Provinsi Rujukan</th>
            <th>Kab/Kota Rujukan</th>
            <th>Faskes Rujukan</th>
            <th>Alasan Dirujuk</th>
            <th>Tanggal Dirujuk</th>
            <th>Faskes yang melakukan PE</th>
            <th>Tanggal Wawancara</th>
            <th>Kematian Malaria di Faskes Rujukan</th>
            <th>Status Pengobatan di Faskes Rujukan</th>
          </tr>
        </thead>
        <tbody>
          {reportsData?.length > 0 ? (
            reportsData?.map((item, index) => (
              <tr key={index}>
                <td>{(index + 1) + ((paginationData?.currentPage - 1) * paginationData?.limit)}</td>
                <td>{item?.province_pencatat_regmal}</td>
                <td>{item?.district_pencatat_regmal}</td>
                <td>{item?.faskes_pencatat_regmal}</td>
                <td>{item?.urbanvillage_id?.split("|")[1]}</td>
                <td>{item?.sub_village}</td>
                <td>{item?.address}</td>
                <td>{item?.kepemilikan_faskes}</td>
                <td>{item?.jenis_faskes}</td>
                <td>{item?.is_foreign_nationality}</td>
                <td>{item?.ktp ? item?.ktp : "Tidak ada KTP"}</td>
                <td>{item?.name}</td>
                <td>{item?.usia?.split(" ")[0]}</td>
                <td>{item?.age_type}</td>
                <td>{item?.gender}</td>
                <td>{item?.oap}</td>
                <td>{item?.pregnancy}</td>
                <td>{item?.province_id?.split("|")[1]}</td>
                <td>{item?.district_id?.split("|")[1]}</td>
                <td>{item?.subdistrict_id?.split("|")[1]}</td>
                <td>{item?.fasyankes_id?.split("|")[1]}</td>
                <td>{item?.urbanvillage_id?.split("|")[1]}</td>
                <td>{item?.is_domicile_outside}</td>
                <td>{item?.domicile_province_id?.split("|")[1]}</td>
                <td>{item?.domicile_district_id?.split("|")[1]}</td>
                <td>{item?.domicile_subdistrict_id?.split("|")[1]}</td>
                <td>{item?.domicile_fasyankes_id?.split("|")[1]}</td>
                <td>{item?.case_from}</td>
                <td>{item?.discovery_activities}</td>
                <td>{item?.ssr}</td>
                <td>{item?.kader}</td>
                <td>{item?.mbs_date}</td>
                <td>{item?.survey_contact_date}</td>
                <td>{item?.first_case_trigger_survey_contact}</td>
                <td>{item?.lab_checking_type}</td>
                <td>{item?.total_rdt}</td>
                <td>{item?.no_reg_pasien}</td>
                <td>{item?.patient_visite_date}</td>
                <td>{item?.lab_checking_date}</td>
                <td>{item?.slide_code}</td>
                <td>{item?.parasyte?.join(", ")}</td>
                <td>{item?.job}</td>
                <td>{item?.disease_degree}</td>
                <td>{item?.recurrence}</td>
                <td>{item?.body_temperature}</td>
                <td>{item?.symptdomatic}</td>
                <td>{item?.symptdomatic_date}</td>
                <td>{item?.gametosit}</td>
                <td>{item?.parasyte_density}</td>
                <td>{item?.weight}</td>
                <td>{item?.anemia_status}</td>
                <td>{item?.asal_penularan}</td>
                <td>{item?.klasifikasi_kasus}</td>
                <td>{item?.medication_date}</td>
                <td>{item?.medication.dhp_tablet}</td>
                <td>{item?.medication.primaquin_tablet}</td>
                <td>{item?.medication.kina_tablet}</td>
                <td>{item?.medication.kina_injeksi}</td>
                <td>{item?.medication.artesunat_injeksi}</td>
                <td>{item?.medication.doksisiklin}</td>
                <td>{item?.medication.tetrasiklin}</td>
                <td>{item?.medication.klindamisin}</td>
                <td>{item?.standard_medication_status}</td>
                <td>{item?.not_standard_medication_reason}</td>
                <td>{item?.treatment}</td>
                <td>{item?.death_with_malaria}</td>
                <td>{item?.referred_to_other_faskes}</td>
                <td>{item?.ref_province_id?.split("|")[1]}</td>
                <td>{item?.ref_district_id?.split("|")[1]}</td>
                <td>{item?.ref_fasyankes_id?.split("|")[1]}</td>
                <td>{item?.ref_reason}</td>
                <td>{item?.ref_date}</td>
                <td>{item?.faskes_melakukan_pe?.split("|")[1]}</td>
                <td>{item?.case_interview_date}</td>
                <td>{item?.kematian_malaria_rujukan}</td>
                <td>{item?.status_pengobatan_rujukan}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={73}>Tidak Ada Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
